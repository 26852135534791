import { NavLink } from "react-router-dom";
import FooterLogo from "../assets/footer-logo.png";
import logo from "../assets/public-logo.png";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { urlPaths } from "../utils/config";
import { useNavigate } from "react-router-dom";
import '../sales.css';
import styled from "styled-components";
import { size } from "../utils/helpers";
import { useLocation } from "react-router-dom";
import mascottver from "../assets/Tekpon-Verified.svg";
import footimgicon from "../assets/1-Deep blue.png";
import thankYouPage from "../pages/sales/thankYouPage";

const SalesPages = ({ children }) => {
    const [isOpen, setOpen] = useState(false);

    const location = useLocation();
    const currentPathname = location.pathname.toLowerCase();
    const navigate = useNavigate();

    const allNavigationLinks = () => {
        return (
            <nav className={isOpen ? "show" : ""}>
                <NavLink to={"/"}>
                    <span className="navigation-link" onClick={() => {
                        setOpen(!isOpen);
                    }}>
                        Home
                    </span>
                </NavLink>
                <NavLink to={urlPaths.pricing}>
                    <span className="navigation-link" onClick={() => {
                        setOpen(!isOpen);
                    }}>
                        Pricing
                    </span>
                </NavLink>
                <NavLink to={urlPaths.results}>
                    <span className="navigation-link" onClick={() => {
                        setOpen(!isOpen)
                    }}>
                        Results
                    </span>
                </NavLink>
                <NavLink to={urlPaths.aboutUs}>
                    <span className="navigation-link" onClick={() => {
                        setOpen(!isOpen)
                    }}>
                        About us
                    </span>
                </NavLink>
                <NavLink to={urlPaths.login} onClick={() => {
                    setOpen(!isOpen)
                }}>
                    <span className="navigation-link login">
                        Login
                    </span>
                </NavLink>
            </nav>
        )
    }

    return (
        <div className="platform-pages sales">
            {urlPaths.comingsoon != currentPathname && urlPaths.comingsoon2 != currentPathname && urlPaths.thankYouPage != currentPathname && urlPaths.custom != currentPathname &&

                <>
                    <div className="mobile">
                        <img src={logo} className="main-logo" alt="Commenter.ai logo" onClick={() => { navigate(urlPaths.homenew); }} />
                        {allNavigationLinks()}
                        <div id="menuToggle">
                            <input type="checkbox" checked={isOpen} onChange={() => { setOpen(!isOpen); }} />
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div><div className="header">
                        <img src={logo} className="main-logo" alt="Main Logo" onClick={() => { navigate(urlPaths.homenew); }} />
                        {allNavigationLinks()}
                    </div></>
            }
            {children}
            <Outlet />
            {urlPaths.comingsoon !== currentPathname && urlPaths.comingsoon2 !== currentPathname && urlPaths.thankYouPage != currentPathname && urlPaths.commenterhome !== currentPathname && urlPaths.custom != currentPathname &&

                <Footer>

                    <Navup>
                        <NavLeftup>
                            <img src={FooterLogo} alt="Footer logo" />
                            <Reserved>
                                <DivFlex>
                                    <Alert>Commenter AI is not affiliated, associated, authorized, endorsed by, or in any way officially connected with the LinkedIn<sup>TM</sup> Corporation,
                                        registered in the U.S. and other countries. LinkedIn<sup>TM</sup> is a trademark of the LinkedIn<sup>TM</sup> Corporation.</Alert>
                                </DivFlex>
                            </Reserved>

                        </NavLeftup>
                        <NavLeftup>
                            <nav>
                                <a href="/blog/" >Blog</a>
                                <NavLink to={urlPaths.commenterhome} >Become a Partner</NavLink>
                                <NavLink to={urlPaths.privacyPolicy} >Privacy Policy</NavLink>
                                <NavLink to={urlPaths.termsOfService} >Terms of Services</NavLink>
                                <NavLink to={urlPaths.contactUs}>Contact Us</NavLink>
                                <NavLink to={urlPaths.aboutUs}>About us</NavLink>
                               

                            </nav>
                            <DesktopLogoAi>
                                <a href="https://tekpon.com/software/commenter-ai/reviews/" target="_blank">
                                    <ImageMascott src={mascottver} alt=" Tekpon-Verified.svg" />
                                </a>
                                <a href="https://theresanaiforthat.com/ai/commenter/?ref=featured&v=560525" target="_blank">
                                    <img width="300" src="https://media.theresanaiforthat.com/featured2.png" />
                                </a>
                                <a href="https://softgist.com/" target="_blank">
                                <img src={footimgicon} alt="footimgicon" />
                                </a>

                            </DesktopLogoAi>
                        </NavLeftup>
                    </Navup>


                    <MobileLogoAi>
                        <a href="https://tekpon.com/software/commenter-ai/reviews/

" target="_blank">
                            <ImageMascott src={mascottver} alt=" Tekpon-Verified.svg" />
                        </a>
                        <a href="https://theresanaiforthat.com/ai/commenter/?ref=featured&v=560525" target="_blank">
                            <img width="300" src="https://media.theresanaiforthat.com/featured2.png" />
                        </a>
                        <a href="https://softgist.com/" target="_blank">
                                <img src={footimgicon} alt="footimgicon" />
                                </a>
                    </MobileLogoAi>
                    <Reserve>© Commenter.ai, All rights reserved.</Reserve>
                </Footer>
            }

            {(urlPaths.comingsoon === currentPathname || urlPaths.comingsoon2 === currentPathname) &&
                <Footer>
                    <Navup1>
                        <img src={FooterLogo} alt="Footer logo" />
                        <nav >
                            {urlPaths.comingsoon2 !== currentPathname &&
                                <NavLink to={urlPaths.contactUs}>Contact Us</NavLink>}
                            {urlPaths.comingsoon2 !== currentPathname &&
                                <NavLink to={urlPaths.aboutUs}>About us</NavLink>}
                            {urlPaths.comingsoon2 !== currentPathname &&
                                <NavLink to={urlPaths.pricing}>Pricing</NavLink>}
                            <NavLink to={urlPaths.privacyPolicy} >Privacy Policy</NavLink>
                            <NavLink to={urlPaths.termsOfService} >Terms of Services</NavLink>


                        </nav>
                    </Navup1>
                    <Reserved>
                        <DivFlex>
                            <Alert1>All product names, logos, and brands are property of their respective owners. All company, product and service names used in this website are for identification purposes only. Use of these names, logos, and brands does not imply endorsement. Commenter AI is not affiliated, associated, authorized, endorsed by, or in any way officially connected with the LinkedIn Corporation, registered in the U.S. and other countries. LinkedIn is a trademark of the LinkedIn Corporation.
                            </Alert1>
                        </DivFlex>
                    </Reserved>
                </Footer>
            }

        </div>
    )
}


const Footer = styled.footer`
    display: flex;
    flex-direction: column;
`

const Navup = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 18px;
    @media only screen and (max-width:991px){
        flex-direction:column;
    }
    @media only screen and (max-width: ${size.minWidthTablet}) {
        display: block;
        text-align: center;
    }
`
const Navup1 = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 18px;
    border-bottom: 3px solid #ffffff78;
    padding-bottom: 15px;
    @media only screen and (max-width: ${size.minWidthTablet}) {
        display: block;
        text-align: center;
    }
    > a {
        @media only screen and (max-width:767px){
    margin-left:0px;
   }
    }
`

const Alert = styled.p`
    font-family: 'Space Grotesk', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 25px;
    color: var(--white);
    text-align: center;
    width: 100%;
    @media only screen and (min-width: 1024px) {
        width: 60%;
        text-align: start;
    }
   > sup{
    font-size: 10px;
    font-family: 'Space Grotesk', sans-serif !important;
   }
    
`
const Alert1 = styled.p`
    font-family: 'Space Grotesk', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 25px;
    color: var(--white);
    text-align: center;
    width: 100%;
    max-width:1130px;
    margin:30px auto;
    @media only screen and (max-width: ${size.minWidthTablet}) {
        width: 100%;
        
    }
`
const Reserved = styled.div`
    display: flex;

   
`;
const MobileLogoAi = styled.div`
    display: none;
    @media only screen and (max-width:767px){
        display: flex;
    padding-bottom: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }
`;

const DivFlex = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    @media only screen and (max-width:767px){
       align-items: center;
    }

`

const Reserve = styled.p`
    font-family: 'Space Grotesk', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 25px;
    color: var(--white);
    text-align: center;
`;
const ImageMascott = styled.img`
    `;
const LastDiv = styled.div`
    display:flex;
    gap:20px;

@media only screen and (max-width:767px){
        display: flex;
    padding-bottom: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }
    > a  {
        @media only screen and (max-width:767px){
            display:none;

        }
    }

`;
const NavLeftup = styled.div`
 @media only screen and (max-width:991px){
            text-align:center;
        }
    `;
const DesktopLogoAi = styled.div`
    display:flex;
    justify-content:end;
    @media only screen and (max-width:991px){
        justify-content:center;
    }
 @media only screen and (max-width:767px){
             display:none;
        }
    `;



export default SalesPages;